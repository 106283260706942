import React from "react";
import "./App.css";
import ReadMoreReact from "read-more-react";
import ShowMoreText from "react-show-more-text";
function App() {
  const authorText = `After vowing not to send young men to Vietnam, President John F. Kennedy was assassinated on 22 November 1963. Will Johnstone hears the news sitting in a US History class.  During his summer vacation on August 4th, 1964 he reads about  an "incident" in the Tonkin Gulf involving US destroyers  and North Vietnamese PT boats.  This "incident"  announced by President Lyndon Johnson later proves to be a half-truth, but Congress gives Johnson power to retaliate.  No war is declared, yet it explodes into one anyway.

Will graduates in 1965 and scrambles to find a college for the fall term.  Things go against him and he is excused in December of that year, losing his 2S student deferment from the military draft.  In July 1966, Will enlists in the Navy three days before his 19th birthday, his draft notice arriving four days later.  Trying for the Sea Bees, he finds the occupation full, and the Navy sends him to Catapult/Arresting Gear "A" school.

The next thing he knows he's on a plane to Manila, PI, and then a long journey by bus through the jungles down to Subic Bay Naval Base to await his Attack Aircraft Carrier the USS Dangerous.  Will is on his way to the Tonkin Gulf to launch "angels of death" onto the North Vietnamese.

Bitter and resentful about the politics and realities of war, Will and his shipmates relax by smoking Cannabis after flight ops are over each day.  Released from the Navy in the Spring of 1970, he takes a road trip to NY to see his girlfriend at her college.  He arrives May 4th and learns about the Kent State Massacre.

This event polarizes the country, and he finds himself driving to Washington D.C. with his girlfriend and three other students to  protest the war and to march on Nixon's White House.  From that day Will's life takes a new path.`;
  // <div className="left-col">
  // <div className="right-col">

  const style = { width: "120px", height: "240px" };
  return (
    <div className="App">
      <header className="App-header">
        <h1>Power, Greed, War & Weed</h1>
        <div className="subhead">
          <h3>A Novel by John Charles</h3>
          <div className="links-row">
            <div className="link-block">
              <h6>Paperback</h6>
              <iframe
                style={style}
                marginWidth="0"
                marginHeight="0"
                scrolling="no"
                frameBorder="0"
                src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=powergreedwar-20&marketplace=amazon&region=US&placement=1644372150&asins=1644372150&linkId=1ca8dd00e38cddc7bff4e18a290a7054&show_border=false&link_opens_in_new_window=false&price_color=333333&title_color=0066c0&bg_color=ffffff"
              />
            </div>
            <div className="link-block">
              <h6>Large Print</h6>
              <iframe
                style={style}
                marginWidth="0"
                marginHeight="0"
                scrolling="no"
                frameBorder="0"
                src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=powergreedwar-20&marketplace=amazon&region=US&placement=1644372215&asins=1644372215&linkId=3a99423c28599ae350de2e216dfff501&show_border=false&link_opens_in_new_window=false&price_color=333333&title_color=0066c0&bg_color=ffffff"
              />
            </div>
            <div className="link-block">
              <h6>Kindle</h6>

              <iframe
                style={style}
                marginWidth="0"
                marginHeight="0"
                scrolling="no"
                frameBorder="0"
                src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=powergreedwar-20&marketplace=amazon&region=US&placement=B0859PKY7K&asins=B0859PKY7K&linkId=d50f872000edc8cbb2beeb750108d526&show_border=false&link_opens_in_new_window=false&price_color=333333&title_color=0066c0&bg_color=ffffff"
              />
            </div>
          </div>
        </div>
      </header>
      <section>
        <div className="about book">
          <h4>About the Book</h4>
          <div>
            <ShowMoreText
              /* Default options */
              lines={8}
              more="Show more"
              less="Show less"
              anchorClass="read-more-link"
              expanded={false}
            >
              {authorText}
            </ShowMoreText>
          </div>
        </div>
        <div className="about">
          <div className="about-head">About the Author</div>
          <div className="author-photo photo fade-in" />
          Coming of age on the leading edge of the Baby Boom, John Charles was
          caught in the vortex of the volatile 60's and the spectre of the
          sinister 70's: a beloved president murdered, another lying the country
          into war, a third resigning in disgrace and a fourth pardoning the
          unpardonable. After observing a lifetime of conspiracy, paranoia and
          equivocation, he felt compelled to share the thoughts and behaviors,
          the hopes and fears of a generation conceived at the end of WWII, a
          time of inspiration and confidence.
        </div>
        <div className="about author-photo-salvio-fill" />
      </section>
      <footer />
    </div>
  );
}

export default App;
